<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      @search="search"
      @reset="reset"
      :fields="searchFields"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>

    <b-card no-body class="mb-0">
      <div class="m-1">
        <b-row>
          <b-col>
            <label>{{ $t("field.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
          <b-col cols="auto" class="text-right">
            <b-row align-h="end">
              <b-col class="pl-0" cols="auto">
                <export-excel-button
                  :resource="resource"
                  :query="query"
                  :params="params"
                  :loading="loading"
                  :repository="LeaveRequestRepository"
                  :fields="exportFields"
                  :search-fields="searchFields"
                  :file-name="$t('breadcrumb.leaveRequest')"
                />
              </b-col>
              <b-col class="px-0" cols="auto">
                <n-column-visibility
                  :fields="fields"
                  :visible-columns="visibleColumns"
                  v-model="visibleColumns"
                  @change="key++"
                ></n-column-visibility>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          ref="table"
          :key="`table-${key}`"
          @sort-changed="sortChanged"
          :visible-columns="visibleColumns"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="query.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          :resource="resource"
          :route="route"
          :viewable="true"
          :updatable="false"
        >
          <template #cell(quantity)="data">
            {{ data.item.quantity }} {{ $tc("time.day", data.item.quantity) }}
          </template>
          <template #cell(state)="data">
            <b-badge
              pill
              :class="{
                'badge-danger': data.item.state == 0,
                'badge-success': data.item.state == 5,
                'badge-warning': [1, 2, 3, 4].includes(data.item.state),
              }"
            >
              {{ $t(`leaveState.${data.item.state}`) }}
            </b-badge>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="query.page"
          @change="changePage"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BAvatar, BBadge } from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import TableFields from "./tableFields";
import SearchInputs from "./searchInput";
import NColumnVisibility from "@/components/NColumnVisibility";
import ExportExcelFields from "./exportExcelFields";
import ExportExcelButton from "@/components/ExportExcelButton";

const LeaveRequestRepository = Repository.get("leaveRequest");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BBadge,

    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    NColumnVisibility,
    ExportExcelButton,

    vSelect,
  },
  watch: {
    perPage() {
      this.changePage();
      this.$refs.pagination.reset();
    },
  },
  computed: {
    visibleColumns: {
      get() {
        return this.$store.state.column.leaveRequest;
      },
      set(value) {
        this.$store.commit("column/SET_LEAVE_REQUEST_COLUMN", value);
      },
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    userData: function () {
      return this.$store.state.profile?.username
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  data() {
    return {
      key: 1,
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        leaveTypeId: this.$route.query.leaveTypeId || [],
        salespersonId: this.$route.query.salespersonId || [],
        departmentId: this.$route.query.departmentId || [],
        positionId: this.$route.query.positionId || [],
        state: this.$route.query.state || [],
        needMyApproval: this.$route.query.needMyApproval || "0",
        requestedDate: this.$route.query.requestedDate || [],
      },
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || "DESC",
        leaveTypeId: this.$route.query.leaveTypeId || [],
        salespersonId: this.$route.query.salespersonId || [],
        departmentId: this.$route.query.departmentId || [],
        positionId: this.$route.query.positionId || [],
        state: this.$route.query.state || [],
        needMyApproval: this.$route.query.needMyApproval || "0",
        requestedDate: this.$route.query.requestedDate || [],
      },

      LeaveRequestRepository,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    search() {
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.getData();
    },
    reset() {
      this.params.needMyApproval = "0";
      this.$refs.search.reset();
      this.search();
    },
    getData() {
      this.loading = true;
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();

      LeaveRequestRepository.index({
        ...this.query,
        searchFields: this.searchFields,
        userId: this.userData.id,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = TableFields;
    const exportFields = ExportExcelFields;
    const searchFields = SearchInputs;
    const resource = "leave-request";
    const route = "leave-request";

    return {
      fields,
      exportFields,
      searchFields,
      resource,
      route,
    };
  },
};
</script>
